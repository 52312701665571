import { createContext, useContext } from 'react';

type ContextValue = {
  playSoundClick: () => void;
  playSoundBomb: () => void;
  playSoundClear: () => void;
  playSoundFlip: () => void;
  playSoundSilver: () => void;
};

export const SoundContext = createContext<ContextValue>({
  playSoundClick: () => undefined,
  playSoundBomb: () => undefined,
  playSoundClear: () => undefined,
  playSoundFlip: () => undefined,
  playSoundSilver: () => undefined,
});

export function usePlaySound() {
  const context = useContext(SoundContext);

  if (context === undefined) {
    throw new Error('usePlaySound must be used within a SoundProvider');
  }

  return context;
}
