import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '~/components/button';
import ButtonImage from '~/components/button-image';
import ScaleImage from '~/components/scale-image';
import { COOLDOWN_TIME_PER_TURN } from '~/config/game';
import { useGameStore } from '~/hooks/use-game';
import { useScale } from '~/hooks/use-scale';
import { checkTask } from '~/utils/game';
import { displayTime } from '~/utils/time';
import ModalClaim from './modal-claim';
import ModalReset from './modal-reset';
import Pool from './pool';

enum Action {
  None,
  Resetting,
  Claiming,
}

function PoolContainer() {
  const queryClient = useQueryClient();
  const [time, setTime] = useState<number>();
  const [rewards, setRewards] = useState(0);
  const [action, setAction] = useState(Action.None);
  const prevTime = useRef(0);
  const { scale } = useScale();
  const turns = useGameStore((state) => state.turns);
  const turnCapacity = useGameStore((state) => state.turnCapacity);
  const lastTurnUpdate = useGameStore((state) => state.lastTurnUpdate);
  const rewardsBonus = useGameStore((state) => state.rewardsBonus);
  const fishRewards = useGameStore((state) => state.fishRewards);
  const fishMission = useGameStore((state) => state.fishMission);
  const claimMission = useGameStore((state) => state.claimMission);
  const refetchTurns = useGameStore((state) => state.refetchTurns);

  const matchedFishes = useMemo(() => {
    return checkTask(fishRewards, fishMission);
  }, [fishRewards, fishMission]);

  const claimable = useMemo(() => {
    return matchedFishes.every((value) => value);
  }, [matchedFishes]);

  const isCounting = useMemo(() => turns < turnCapacity, [turnCapacity, turns]);

  useEffect(() => {
    if (turns >= turnCapacity || lastTurnUpdate === prevTime.current) return;

    prevTime.current = lastTurnUpdate;
    const now = Math.floor(Date.now() / 1000);
    const nextTime = lastTurnUpdate + COOLDOWN_TIME_PER_TURN;
    const time = nextTime - now;
    setTime(time);
  }, [lastTurnUpdate, turnCapacity, turns]);

  useEffect(() => {
    if (isCounting && time <= 0) {
      refetchTurns();
    }
  }, [isCounting, refetchTurns, time]);

  useEffect(() => {
    if (!isCounting) return;

    const id = setInterval(
      () => setTime((prevState) => (prevState - 1 > 0 ? prevState - 1 : 0)),
      1000
    );

    return () => clearInterval(id);
  }, [isCounting]);

  return (
    <div style={{ minHeight: 200 * scale }} className="relative">
      <ScaleImage src="/img/bg-pool.png" alt="pool" width={414} height={189} />
      <Pool fishRewards={fishRewards} />
      <img src="/img/frame-pool.png" alt="frame" className="absolute inset-0" />
      <div className="absolute left-1.5 top-1.5">
        <ScaleImage src="/img/frame-bonus.png" alt="bonus" height={32} />
        <div className="absolute left-14 top-1/2 -translate-y-1/2 text-[#b0ff6a]">
          {rewardsBonus}%
        </div>
      </div>
      {fishMission.length > 0 ? (
        <div className="absolute bottom-2 flex gap-x-0.5 pl-3 pr-1">
          {claimable ? (
            <ButtonImage
              src="/img/btn-claim.png"
              width={94}
              onClick={async () => {
                const rewards = await claimMission();
                await queryClient.invalidateQueries({ queryKey: ['fishStorage'] });
                setRewards(rewards);
                setAction(Action.Claiming);
              }}
            />
          ) : (
            fishMission.map((id, index) => {
              const isMatch = matchedFishes[index];

              return (
                <div key={index} className="relative">
                  <ScaleImage
                    src={isMatch ? '/img/slot-green.png' : '/img/slot-blue.png'}
                    alt="slot"
                    height={30}
                  />
                  <ScaleImage
                    src={`/img/fish/${id}.png`}
                    alt="slot"
                    height={20}
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
              );
            })
          )}
          <Button onClick={() => setAction(Action.Resetting)}>
            <ScaleImage src="/img/icon-reset.png" alt="reset" height={30} />
          </Button>
        </div>
      ) : null}
      <div className="absolute bottom-4 right-1 text-center">
        <div style={{ marginBottom: 4 * scale, marginRight: 2 * scale }}>
          <span style={{ right: -4 * scale, top: -8 * scale }} className="relative text-sm">
            {turns}
          </span>
          <span className="text-lg text-[#e6a648]">/</span>
          <span style={{ right: 6 * scale }} className="relative text-xs text-[#e6a648]">
            {turnCapacity}
          </span>
        </div>
      </div>
      {time >= 0 && turns < turnCapacity ? (
        <div
          style={{ bottom: 6 * scale, right: scale }}
          className="absolute w-8 -translate-x-1/2 text-center text-xs"
        >
          {displayTime(time)}
        </div>
      ) : null}
      <ModalReset isOpen={action === Action.Resetting} onClose={() => setAction(Action.None)} />
      <ModalClaim
        rewards={rewards}
        isOpen={action === Action.Claiming}
        onClose={() => setAction(Action.None)}
      />
    </div>
  );
}

export default PoolContainer;
