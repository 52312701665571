import { useState } from 'react';
import Button from '~/components/button';
import Modal from '~/components/modal';
import ScaleImage from '~/components/scale-image';
import { useFishes } from '~/hooks/use-fishes';
import { useFlipEvent } from '~/hooks/use-flip-event';
import { useGameStore } from '~/hooks/use-game';
import { useSilver } from '~/hooks/use-silver';
import { useTileMap } from '~/hooks/use-tile-map';
import Board from './components/board';
import ModalDailyReward from './components/modal-daily-reward';
import PoolContainer from './components/pool-container';

enum ModalName {
  Guideline,
  DailyReward,
}

function HomePage() {
  const flip = useFlipEvent();
  const [currentModal, setCurrentModal] = useState<ModalName>();
  const level = useGameStore((state) => state.level);
  const silver = useGameStore((state) => state.silver);

  useSilver();
  useFishes();
  useTileMap();

  return (
    <div className="relative flex h-full w-full flex-col overflow-y-auto bg-beach bg-cover">
      <div className="relative -bottom-2 flex flex-1 flex-col items-center justify-center gap-y-1">
        <div className="text-center text-lg text-[#9b532b]">LEVEL {level}</div>
        <div className="relative">
          <ScaleImage src="/img/bar-balance.png" alt="balance" height={44} />
          <div className="absolute inset-0 flex items-center justify-center gap-x-2">
            <ScaleImage src="/img/icon-silver.png" alt="silver" height={28} />
            <div className="text-lg">{silver}</div>
          </div>
        </div>
      </div>
      <Board flip={flip} />
      <PoolContainer />
      <Button
        onClick={() => setCurrentModal(ModalName.DailyReward)}
        className="absolute left-1 top-2"
      >
        <ScaleImage src="/img/icon-checkin.png" alt="checkin" height={32} />
      </Button>
      <Button
        onClick={() => setCurrentModal(ModalName.Guideline)}
        className="absolute right-1 top-2"
      >
        <ScaleImage src="/img/icon-info.png" alt="info" height={32} />
      </Button>
      <ModalDailyReward
        isOpen={currentModal === ModalName.DailyReward}
        onClose={() => setCurrentModal(undefined)}
      />
      <Modal
        isOpen={currentModal === ModalName.Guideline}
        onClose={() => setCurrentModal(undefined)}
      >
        <ScaleImage src="/img/guideline.png" alt="guideline" width={382} height={636} />
      </Modal>
    </div>
  );
}

export default HomePage;
