export function checkTask(fishRewards: number[], fishMission: number[]) {
  const mission = new Array(3).fill(false);

  if (fishRewards.length === 0 || fishMission.length === 0) {
    return mission;
  }

  const fishCount = [];

  fishRewards.forEach((id) => {
    fishCount[id] = (fishCount[id] ?? 0) + 1;
  });

  fishMission.forEach((id, index) => {
    fishCount[id] = fishCount[id] ?? 0;

    if (fishCount[id] > 0) {
      mission[index] = true;
    }

    fishCount[id]--;
  });

  return mission;
}
