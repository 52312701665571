import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClaimTaskResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useClaimTask() {
  const session = useGameStore((state) => state.session);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (taskId: number) => {
      const { payload } = await client.rpc(session, 'rpc.social_task.claim_task', {
        task_id: taskId,
      });

      return payload as ClaimTaskResponse;
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tasks'] }),
  });
}
