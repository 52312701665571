import BoxImage from '~/components/box-image';
import ButtonImage from '~/components/button-image';
import Modal from '~/components/modal';
import ScaleImage from '~/components/scale-image';
import { BONUS_PER_CLAIM } from '~/config/game';

type Props = {
  rewards: number;
  isOpen: boolean;
  onClose: () => void;
};

function ModalClaim({ rewards, isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <BoxImage
        src="/img/board-popup.png"
        alt="popup"
        width={382}
        height={407}
        className="flex flex-col items-center py-10"
      >
        <div className="text-center text-xl">{`You've received`}</div>
        <div className="flex flex-1 items-center justify-center gap-y-2">
          <div className="flex items-end gap-x-10">
            <div className="flex flex-col items-center gap-y-2">
              <div className="text-stroke-black text-3xl">{BONUS_PER_CLAIM}%</div>
              <div className="text-stroke-black text-xl">Bonus</div>
            </div>
            <div className="flex flex-col items-center gap-y-2">
              <div className="flex h-14 items-center">
                <ScaleImage src="/img/icon-silver.png" height={52} />
              </div>
              <div className="text-stroke-black text-xl">x{rewards}</div>
            </div>
          </div>
        </div>
        <ButtonImage
          src="/img/btn-green.png"
          height={50}
          className="flex items-center justify-center text-lg"
          onClick={onClose}
        >
          Next
        </ButtonImage>
      </BoxImage>
    </Modal>
  );
}

export default ModalClaim;
