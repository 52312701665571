import { withSentryReactRouterV6Routing } from '@sentry/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MemoryRouter, Route, Routes } from 'react-router-dom';
import ErrorProvider from './components/error-provider';
import Layout from './components/layout';
import ModalCheckin from './components/modal-checkin';
import ModalClaimMaintenance from './components/modal-claim-maintenance';
import ScaleProvider from './components/scale-provider';
import SoundProvider from './components/sound-provider';
import BootPage from './pages/boot';
import HomePage from './pages/home';
import RankPage from './pages/rank';
import ReferralPage from './pages/referral';
import TaskPage from './pages/task';

const queryClient = new QueryClient();
const SentryRoutes = withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <ErrorProvider>
      <QueryClientProvider client={queryClient}>
        <ScaleProvider>
          <SoundProvider>
            <MemoryRouter>
              <SentryRoutes>
                <Route path="/" element={<BootPage />} />
                <Route element={<Layout />}>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/rank" element={<RankPage />} />
                  <Route path="/referral" element={<ReferralPage />} />
                  <Route path="/task" element={<TaskPage />} />
                </Route>
              </SentryRoutes>
              <ModalCheckin />
              <ModalClaimMaintenance />
            </MemoryRouter>
          </SoundProvider>
        </ScaleProvider>
      </QueryClientProvider>
    </ErrorProvider>
  );
}

export default App;
