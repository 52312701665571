import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VerifyTaskResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useVerifyTask() {
  const session = useGameStore((state) => state.session);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (taskId: number) => {
      const { payload } = await client.rpc(session, 'rpc.social_task.verify_task', {
        task_id: taskId,
        timestamp: Date.now(),
      });

      return payload as VerifyTaskResponse;
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tasks'] }),
  });
}
