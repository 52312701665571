import { Session } from '@heroiclabs/nakama-js';
import WebApp from '@twa-dev/sdk';
import { Buffer } from 'buffer';
import { create } from 'zustand';
import { SILVER_NEEDED_TO_RESET_MISSION } from '~/config/game';
import {
  ClaimMissionResponse,
  FlipSquareResponse,
  GetNextTileMapResponse,
  GetTileMapResponse,
  ResetMissionResponse,
} from '~/types/nakama';
import { client } from '~/utils/nakama-client';

export enum GameStatus {
  None,
  Flip,
  Bomb,
  Clear,
}

type Store = {
  session?: Session;
  level: number;
  silver: number;
  status: GameStatus;
  totalUnlocked: number;
  neededUnlocked: number;
  tiles: { unlocked: boolean; value: number | null }[];
  turns: number;
  turnCapacity: number;
  lastTurnUpdate: number;
  fishRewards: number[];
  fishMission: number[];
  rewardsBonus: number;
  rewards: [number, number];
  authenticate: () => Promise<void>;
  setSession: (session: Session) => void;
  setStatus: (status: GameStatus) => void;
  setSilver: (silver: number) => void;
  addRewards: (addedTurns: number, addedSilver: number) => void;
  setFishes: (value: {
    fishRewards: number[];
    fishMission: number[];
    rewardsBonus: number;
  }) => void;
  setTileMap: (value: {
    level: number;
    totalUnlocked: number;
    tiles: { unlocked: boolean; value: number | null }[];
    turns: number;
    turnCapacity: number;
    lastTurnUpdate: number;
  }) => void;
  generateNextTileMap: () => Promise<void>;
  resetTileMap: () => Promise<void>;
  resetRewards: () => void;
  refetchTurns: () => Promise<void>;
  flipTile: (response: FlipSquareResponse, tileIndex: number) => Promise<boolean>;
  resetMission: () => Promise<void>;
  claimMission: () => Promise<number>;
};

export const useGameStore = create<Store>((set, get) => ({
  level: 0,
  silver: 0,
  status: GameStatus.None,
  totalUnlocked: 0,
  neededUnlocked: 50,
  tiles: new Array(64).fill({ unlocked: false, value: null }),
  turns: 0,
  turnCapacity: 100,
  lastTurnUpdate: 0,
  fishRewards: [],
  fishMission: [],
  rewardsBonus: 0,
  rewards: [0, 0],
  authenticate: async () => {
    const {
      initData,
      initDataUnsafe: { user },
    } = WebApp;
    const telegramId = String(user?.id ?? '');
    const id = `fofgame-${telegramId}`;
    const username = user?.username ?? '';
    const firstname = user?.first_name ?? '';
    const lastname = user?.last_name ?? '';
    const fullname = firstname + ' ' + lastname;
    const displayName = fullname.trim();
    const encodedDisplayName = Buffer.from(displayName).toString('base64');

    const session = await client.authenticateCustom(id, true, username, {
      displayName: encodedDisplayName,
      telegramId: telegramId,
      initData,
    });

    get().setSession(session);
  },
  setSession: (session) => set({ session }),
  setStatus: (status) => set({ status }),
  setSilver: (silver) => set({ silver }),
  addRewards: (addedTurns, addedSilver) => {
    const { turns, silver } = get();

    if (addedTurns) set({ turns: turns + addedTurns });
    if (addedSilver) set({ silver: silver + addedSilver });
  },
  setFishes: async ({ rewardsBonus, fishRewards, fishMission }) => {
    set({ rewardsBonus, fishRewards, fishMission });
  },
  setTileMap: ({ level, totalUnlocked, tiles, turns, turnCapacity, lastTurnUpdate }) =>
    set({ level, totalUnlocked, tiles, turns, turnCapacity, lastTurnUpdate }),
  generateNextTileMap: async () => {
    set({ status: GameStatus.Clear });
    const session = get().session;
    const { payload } = await client.rpc(session, 'rpc_flipmap_generate_next_map_level', {});
    const { silver, fish, success, tileMap } = payload as GetNextTileMapResponse;
    const { mapLevel, totalUnlocked } = tileMap;

    if (success) {
      set({
        rewards: [fish, silver],
        fishRewards: [...get().fishRewards, fish],
        silver: get().silver + silver,
        level: mapLevel,
        totalUnlocked,
        tiles: new Array(64).fill({ unlocked: false, value: null }),
      });
    }
  },
  resetTileMap: async () => {
    set({ status: GameStatus.Bomb });
    const session = get().session;
    const { payload } = await client.rpc(session, 'rpc_flipmap_bomb_reset_map', {});
    const { success, tileMap } = payload as GetTileMapResponse;
    const { mapLevel, totalUnlocked } = tileMap;

    if (success) {
      set({
        level: mapLevel,
        totalUnlocked,
        tiles: new Array(64).fill({ unlocked: false, value: null }),
      });
    }
  },
  resetRewards: () => set({ rewards: [0, 0] }),
  refetchTurns: async () => {
    const session = get().session;
    const { payload } = await client.rpc(session, 'rpc_flipmap_get_tile_map', {});
    const { success, flipTurns } = payload as GetTileMapResponse;

    if (success) {
      const { turns, turnCapacity, lastTurnUpdate } = flipTurns;
      set({ turns, turnCapacity, lastTurnUpdate });
    }
  },
  flipTile: async (response, tileIndex) => {
    const { success, results, flipTurns } = response;

    if (success) {
      const tiles = get().tiles;
      let totalUnlocked = get().totalUnlocked;
      let silver = get().silver;
      const neededUnlocked = get().neededUnlocked;

      const { isBomb, silvers } = results;
      const { turns, lastTurnUpdate } = flipTurns;

      tiles[tileIndex - 1] = {
        unlocked: true,
        value: silvers,
      };

      if (silvers > 0) {
        silver += silvers;
      }

      totalUnlocked++;

      set({ silver, turns, lastTurnUpdate });

      if (isBomb) {
        await get().resetTileMap();
        return;
      }

      if (totalUnlocked === neededUnlocked) {
        await get().generateNextTileMap();
        return;
      }

      set({ tiles, totalUnlocked, status: GameStatus.None });

      return silvers > 0;
    }
  },
  resetMission: async () => {
    const session = get().session;
    const { payload } = await client.rpc(session, 'rpc_fishpool_refresh_task', {});
    const { task } = payload as ResetMissionResponse;
    set({ fishMission: task.fish, silver: get().silver - SILVER_NEEDED_TO_RESET_MISSION });
  },
  claimMission: async () => {
    const session = get().session;
    const { payload } = await client.rpc(session, 'rpc_fishpool_claim_task', {});
    const { rewards } = payload as ClaimMissionResponse;
    set({ silver: get().silver + rewards });

    return rewards;
  },
}));
