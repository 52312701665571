import { createContext, useContext } from 'react';
import { getHeight, getWidth, getX, getY } from '~/utils/scale';

type ContextValue = {
  x: number;
  y: number;
  width: number;
  height: number;
  scale: number;
};

export const ScaleContext = createContext<ContextValue | undefined>({
  x: getX(),
  y: getY(),
  width: getWidth(),
  height: getHeight(),
  scale: 1,
});

export function useScale() {
  const context = useContext(ScaleContext);

  if (context === undefined) {
    throw new Error('useScale must be used within a ScaleProvider');
  }

  return context;
}
