import { Application, Assets, Container, Sprite, Spritesheet } from 'pixi.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fishConfigs } from '~/config/fish';
import { useScale } from '~/hooks/use-scale';

const POOL_WIDTH = 414;
const POOL_HEIGHT = 200;
const PADDING = 20;

type FishSprite = Sprite & {
  fixedX?: number;
  fixedY?: number;
  direction: number;
  rtl: boolean;
  speed: number;
};

type Props = {
  fishRewards: number[];
};

function Pool({ fishRewards }: Props) {
  const [isReady, setIsReady] = useState(false);
  const [fishes, setFishes] = useState(fishRewards);
  const ref = useRef<HTMLCanvasElement>(null);
  const appRef = useRef<Application>();
  const containerRef = useRef<Container>();
  const tickerRef = useRef(false);

  const { scale } = useScale();

  const width = POOL_WIDTH * scale;
  const height = POOL_HEIGHT * scale;

  const loadAsset = useCallback(async (name: string) => {
    let spritesheet: Spritesheet = Assets.cache.get(name);

    if (!spritesheet) {
      spritesheet = await Assets.load(name);
    }

    return spritesheet;
  }, []);

  const setupTicker = useCallback(() => {
    if (!appRef.current || tickerRef.current) return;

    appRef.current.ticker.add((time) => {
      if (!containerRef.current) return;

      containerRef.current.children.forEach((fishSprite) => {
        const fish = fishSprite as FishSprite;
        let direction = fish.direction;
        let newX = fish.x + Math.cos(fish.direction) * fish.speed * time.deltaTime;
        fish.rtl = newX < fish.x;

        if (newX < PADDING) {
          direction = Math.PI - direction;
          newX = PADDING;
        }

        if (newX > width - PADDING) {
          direction = Math.PI - direction;
          newX = width - PADDING;
        }

        let newY = fish.y + Math.sin(fish.direction) * fish.speed * time.deltaTime;

        if (newY < PADDING || newY > height - PADDING) {
          direction = -direction;
          newY = newY < PADDING ? PADDING : height - PADDING;
        }

        fish.x = fish.fixedX ?? newX;
        fish.y = fish.fixedY ?? newY;
        fish.direction = direction;
        fish.scale.x = Math.abs(fish.scale.x) * (fish.rtl ? -1 : 1);
      });
    });

    tickerRef.current = true;
  }, [width, height]);

  const addFishes = useCallback(
    (spritesheet: Spritesheet) => {
      if (!appRef.current) return;

      if (containerRef.current) {
        appRef.current.stage.removeChild(containerRef.current);
        containerRef.current.destroy();
      }

      containerRef.current = new Container();
      appRef.current.stage.addChild(containerRef.current);

      fishes.forEach((id) => {
        const { image, fixedX, fixedY, scale = 0.6, speed = 1 } = fishConfigs[id - 1];
        const fish = Sprite.from(spritesheet.textures[image]) as FishSprite;

        fish.anchor.set(0.5);
        fish.x = fixedX ?? Math.random() * (width - 2 * PADDING) + PADDING;
        fish.y = fixedY ?? Math.random() * (height - 2 * PADDING) + PADDING;
        fish.fixedX = fixedX;
        fish.fixedY = fixedY;
        fish.direction = Math.random() * Math.PI * 2;
        fish.scale.set(scale);
        fish.speed = speed;

        containerRef.current.addChild(fish);
      });
    },
    [fishes, width, height]
  );

  const createApp = useCallback(() => {
    if (!ref.current) return;

    const app = new Application();
    app
      .init({
        width,
        height,
        backgroundAlpha: 0,
        canvas: ref.current,
      })
      .then(() => {
        appRef.current = app;
        loadAsset('sprite/fish.json').then((spritesheet) => {
          addFishes(spritesheet);
          setupTicker();
          setIsReady(true);
        });
      });
  }, [width, height, addFishes, loadAsset, setupTicker]);

  useEffect(() => {
    createApp();
  }, [createApp]);

  useEffect(() => {
    if (isReady && appRef.current && fishRewards.length !== fishes.length) {
      setFishes(fishRewards);
      createApp();
    }
  }, [isReady, fishes, fishRewards, createApp]);

  return <canvas ref={ref} className="absolute inset-0" width={width} height={height} />;
}

export default Pool;
