import BoxImage from '~/components/box-image';
import ScaleImage from '~/components/scale-image';

type Props = {
  active?: boolean;
  rankNo: number;
  name: string;
  points: number;
};

function RankItem({ active = false, rankNo, name, points }: Props) {
  return (
    <BoxImage
      src={active ? '/img/bar-rank-active.png' : '/img/bar-rank.png'}
      width={380}
      height={53}
      className="flex items-center justify-between px-2"
    >
      <div className="flex items-center gap-x-4">
        <div className="text-stroke-black flex w-10 items-center justify-center">
          {rankNo <= 3 ? (
            <BoxImage
              src={`/img/star-${rankNo}.png`}
              height={36}
              className="flex items-center justify-center"
            >
              {rankNo}
            </BoxImage>
          ) : (
            <div>{rankNo}</div>
          )}
        </div>
        <div className="max-w-24 truncate sm:max-w-32">{name}</div>
      </div>
      <div className="relative">
        <BoxImage
          src={active ? '/img/bar-rank-point-active.png' : '/img/bar-rank-point.png'}
          width={130}
          className="text-stroke-black flex items-center justify-end px-1"
        >
          {points.toLocaleString()}
        </BoxImage>
        <ScaleImage
          src="/img/icon-silver.png"
          className="absolute -left-2 top-1/2 -translate-y-1/2"
          height={28}
        />
      </div>
    </BoxImage>
  );
}

export default RankItem;
