import { Assets } from 'pixi.js';

export async function preloadImages() {
  const images = import.meta.glob('/public/img/**/*.(png|jpe?g)');
  const filenames = Object.keys(images).map((filename) => filename.replace('/public', ''));

  function loadImage(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(src);
      img.onerror = reject;
      img.src = src;
    });
  }

  await Assets.load(['sprite/bomb.json', 'sprite/wave.json', 'sprite/fish.json']);

  return Promise.all(filenames.map((src) => loadImage(src)));
}
