import { useQuery } from '@tanstack/react-query';
import { IsClaimedMaintenanceResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useIsClaimedMaintenance() {
  const session = useGameStore((state) => state.session);

  return useQuery({
    queryKey: ['isClaimedMaintenance'],
    queryFn: async () => {
      const { payload } = await client.rpc(session, 'rpc_maintenance_rewards', {});

      const { maintananceRewards } = payload as IsClaimedMaintenanceResponse;

      return maintananceRewards.isClaimed;
    },
    enabled: !!session,
  });
}
