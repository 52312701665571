import { ErrorBoundary, setTag } from '@sentry/react';
import WebApp from '@twa-dev/sdk';
import { ReactNode, useEffect, useState } from 'react';
import { ErrorContext } from '~/hooks/use-report-error';
import { parseError } from '~/utils/error';
import { capitalizeFirstLetter } from '~/utils/string';
import ModalError from './modal-error';

type Props = {
  children: ReactNode;
};

const ignoredErrors = ['out of turns', 'Share canceled'];

function ErrorProvider({ children }: Props) {
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const username = WebApp.initDataUnsafe?.user?.username;
    setTag('username', username);
  }, []);

  return (
    <ErrorBoundary
      fallback={
        <div className="flex h-full w-full flex-col items-center justify-center">
          <div className="text-md">Something went wrong!</div>
        </div>
      }
    >
      <ErrorContext.Provider
        value={{
          reportError: async (error, options = {}) => {
            const { silent = false } = options;

            const message = await parseError(error);

            if (!ignoredErrors.includes(message)) {
              console.error(error);
            }

            if (!silent) {
              setErrorMessage(capitalizeFirstLetter(message));
            }
          },
        }}
      >
        {children}
      </ErrorContext.Provider>
      <ModalError
        message={errorMessage}
        isOpen={Boolean(errorMessage)}
        onClose={() => setErrorMessage(undefined)}
      />
    </ErrorBoundary>
  );
}

export default ErrorProvider;
