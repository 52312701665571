import { useState } from 'react';
import BoxImage from '~/components/box-image';
import ButtonImage from '~/components/button-image';
import Modal from '~/components/modal';
import ScaleImage from '~/components/scale-image';
import { SILVER_NEEDED_TO_RESET_MISSION } from '~/config/game';
import { useGameStore } from '~/hooks/use-game';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function ModalReset({ isOpen, onClose }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const silver = useGameStore((state) => state.silver);
  const resetMission = useGameStore((state) => state.resetMission);

  const disabled = silver < SILVER_NEEDED_TO_RESET_MISSION;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <BoxImage
        src="/img/board-popup.png"
        alt="popup"
        width={382}
        height={407}
        className="flex flex-col py-10"
      >
        <div className="flex flex-1 flex-col items-center justify-center gap-y-4">
          <div className="flex items-center gap-x-2">
            <ScaleImage src="/img/icon-silver.png" height={40} />
            <div className="text-stroke-black text-xl">x{SILVER_NEEDED_TO_RESET_MISSION}</div>
          </div>
          <div className="text-stroke-black text-xl">Needed to reset</div>
        </div>
        <div className="flex items-center justify-evenly">
          <ButtonImage
            src="/img/btn-red.png"
            height={50}
            className="flex items-center justify-center text-lg"
            onClick={onClose}
          >
            Cancel
          </ButtonImage>
          <ButtonImage
            src={disabled ? '/img/btn-gray.png' : '/img/btn-green.png'}
            height={50}
            className="flex items-center justify-center text-lg"
            disabled={disabled}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await resetMission();
              setIsLoading(false);
              onClose();
            }}
          >
            OK
          </ButtonImage>
        </div>
      </BoxImage>
    </Modal>
  );
}

export default ModalReset;
