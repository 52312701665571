import { Link, Outlet } from 'react-router-dom';
import { usePlaySound } from '~/hooks/use-play-sound';
import ScaleImage from './scale-image';

const routes = [
  { name: 'Home', path: '/home', icon: '/img/icon-home.png' },
  { name: 'Rank', path: '/rank', icon: '/img/icon-rank.png' },
  { name: 'Referral', path: '/referral', icon: '/img/icon-referral.png' },
  { name: 'Task', path: '/task', icon: '/img/icon-task.png' },
];

function Layout() {
  const { playSoundClick } = usePlaySound();

  return (
    <div className="flex h-full w-full flex-col text-base">
      <main className="flex-1 overflow-hidden">
        <Outlet />
      </main>
      <nav className="flex bg-bar-bottom">
        {routes.map(({ name, path, icon }, index) => (
          <Link
            key={index}
            to={path}
            onClick={() => playSoundClick()}
            className="flex flex-1 flex-col items-center justify-end bg-tab bg-cover pb-2 pt-4"
          >
            <ScaleImage src={icon} alt="icon" height={32} />
            <div className="mt-1 text-[#828475]">{name}</div>
          </Link>
        ))}
      </nav>
    </div>
  );
}

export default Layout;
