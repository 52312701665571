import { useQuery } from '@tanstack/react-query';
import { FishPoolStorage, FishTaskPoolStorage } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useFishes() {
  const session = useGameStore((state) => state.session);
  const setFishes = useGameStore((state) => state.setFishes);

  return useQuery({
    queryKey: ['fishStorage'],
    queryFn: async () => {
      const data = await client.readStorageObjects(session, {
        object_ids: ['fish_pool', 'fish_task_pool'].map((key) => ({
          collection: key,
          key: key,
          user_id: session.user_id,
        })),
      });

      let fishRewards: number[], fishMission: number[], rewardsBonus: number;

      data.objects.forEach((o) => {
        if (o.collection === 'fish_pool') {
          const value = o.value as FishPoolStorage;
          fishRewards = value.fishs;
          rewardsBonus = value.rewardsBonus;
        }

        if (o.collection === 'fish_task_pool') {
          const value = o.value as FishTaskPoolStorage;
          fishMission = value.fish;
        }
      });

      const value = { fishRewards, fishMission, rewardsBonus };

      setFishes(value);

      return value;
    },
    enabled: !!session,
  });
}
