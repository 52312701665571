import { useQuery } from '@tanstack/react-query';
import { ReferralInfoStorage } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useReferralInfo() {
  const session = useGameStore((state) => state.session);

  return useQuery({
    queryKey: ['referralInfo'],
    queryFn: async () => {
      const data = await client.readStorageObjects(session, {
        object_ids: [
          {
            collection: 'account',
            key: 'referral_info',
            user_id: session.user_id,
          },
        ],
      });

      return data.objects[0].value as ReferralInfoStorage;
    },
    enabled: !!session,
  });
}
