import { FishConfig } from '~/types/game';

export const fishConfigs: FishConfig[] = [
  { image: 'fish1.png' },
  { image: 'fish2.png' },
  { image: 'fish3.png' },
  { image: 'fish4.png' },
  { image: 'fish5.png' },
  { image: 'fish6.png' },
  { image: 'fish7.png', fixedY: 160 },
  { image: 'fish8.png' },
  { image: 'fish9.png' },
  { image: 'fish10.png' },
  { image: 'fish11.png' },
  { image: 'fish12.png' },
  { image: 'fish13.png' },
  { image: 'fish14.png' },
  { image: 'fish15.png', fixedY: 60 },
  { image: 'fish16.png' },
  { image: 'fish17.png' },
  { image: 'fish18.png', fixedX: 240 },
  { image: 'fish19.png', fixedX: 280 },
  { image: 'fish20.png', fixedY: 180 },
  { image: 'fish21.png' },
  { image: 'fish22.png' },
  { image: 'fish23.png' },
  { image: 'fish24.png', fixedY: 100 },
  { image: 'fish25.png' },
  { image: 'fish26.png' },
  { image: 'fish27.png', fixedX: 200 },
  { image: 'fish28.png', fixedY: 160 },
  { image: 'fish29.png' },
  { image: 'fish30.png' },
  { image: 'fish31.png' },
];
