import { createContext, useContext } from 'react';

type ContextValue = {
  reportError: (error: unknown, options?: { silent?: boolean }) => Promise<void>;
};

export const ErrorContext = createContext<ContextValue>({
  reportError: () => undefined,
});

export function useReportError() {
  const context = useContext(ErrorContext);

  if (context === undefined) {
    throw new Error('useReportError must be used within a ErrorProvider');
  }

  return context.reportError;
}
