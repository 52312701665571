import { useQuery } from '@tanstack/react-query';
import { TasksResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useTasks() {
  const session = useGameStore((state) => state.session);

  return useQuery({
    queryKey: ['tasks'],
    queryFn: async () => {
      const { payload } = await client.rpc(session, 'rpc.social_task.get_tasks', {});

      return payload as TasksResponse;
    },
    enabled: !!session,
  });
}
