import * as Sentry from '@sentry/react';
import WebApp from '@twa-dev/sdk';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './app';
import { SENTRY_DSN } from './config/env';
import './index.css';

WebApp.ready();
WebApp.expand();
WebApp.enableClosingConfirmation();

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  enabled: import.meta.env.PROD,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['https://fof-ish.fofgame.xyz'],
});

createRoot(document.getElementById('root')).render(<App />);
