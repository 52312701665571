import { useCallback, useMemo } from 'react';
import BoxImage from '~/components/box-image';
import ButtonImage from '~/components/button-image';
import ScaleImage from '~/components/scale-image';
import { REF_LINK_PREFIX } from '~/config/env';
import { useCopy } from '~/hooks/use-copy';
import { useReferralInfo } from '~/hooks/use-referral-info';
import { useReportError } from '~/hooks/use-report-error';

function ReferralPage() {
  const { data } = useReferralInfo();
  const { copied, copy } = useCopy();
  const reportError = useReportError();

  const inviteLink = useMemo(() => {
    if (!data) return '';

    return REF_LINK_PREFIX + data.refCode;
  }, [data]);

  const share = useCallback(async () => {
    if (navigator.canShare) {
      try {
        await navigator.share({
          url: inviteLink,
        });
      } catch (error) {
        reportError(error, { silent: true });
      }
    }
  }, [inviteLink, reportError]);

  return (
    <div className="relative h-full w-full overflow-y-auto bg-ref bg-cover p-4">
      <div className="pt-4 text-center">
        Invite friends{' '}
        <ScaleImage src="/img/icon-user.png" alt="user" height={32} className="inline" /> and get
        rewards
      </div>
      <div className="mt-4 flex gap-x-8">
        <BoxImage src="/img/bar-ref-info.png" className="flex flex-col gap-y-2 p-4">
          <div className="text-xs sm:text-sm">Friends invited</div>
          <div className="flex flex-1 items-center gap-x-2">
            <ScaleImage src="/img/icon-user.png" alt="user" height={32} />
            <span className="sm:text-lg">x{data?.totalRef ?? 0}</span>
          </div>
        </BoxImage>
        <BoxImage src="/img/bar-ref-info.png" className="flex flex-col gap-y-2 p-4">
          <div className="text-xs sm:text-sm">Turns earned</div>
          <div className="flex flex-1 items-center gap-x-2">
            <ScaleImage src="/img/icon-turn.png" alt="turn" height={32} />
            <span className="sm:text-lg">x{data?.totalTurns ?? 0}</span>
          </div>
        </BoxImage>
      </div>
      <div className="mb-4 mt-2 text-sm text-[#757865]">
        Invited a friend will receive a gift of <span className="text-white">30</span> Turns.
      </div>
      <BoxImage src="/img/board-ref.png" width={414} height={370} className="p-1.5">
        {data ? (
          <div className="h-full overflow-y-auto bg-[#34231a]">
            {data.refs.map(({ displayname }, index) => (
              <div key={index} className="flex items-center gap-x-2 p-4 odd:bg-[#452f23]">
                <div className="w-12">#{index + 1}</div>
                <div className="max-w-24 truncate sm:max-w-32">{displayname}</div>
              </div>
            ))}
          </div>
        ) : null}
      </BoxImage>
      <div className="mb-4 mt-2 text-sm tracking-tighter text-[#757865]">
        Displaying the most recent 300 records only
      </div>
      <div className="flex items-center gap-x-8">
        <ButtonImage
          src="/img/bar-invite-friend.png"
          onClick={share}
          className="flex items-center justify-center text-lg"
        >
          Invite Friends!
        </ButtonImage>
        <ButtonImage
          src="/img/btn-brown.png"
          width={60}
          onClick={() => copy(inviteLink)}
          className="flex items-center justify-center"
        >
          {copied ? (
            <ScaleImage src="/img/icon-check.png" height={16} />
          ) : (
            <ScaleImage src="/img/icon-copy.png" height={20} />
          )}
        </ButtonImage>
      </div>
    </div>
  );
}

export default ReferralPage;
