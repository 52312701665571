import { useQuery } from '@tanstack/react-query';
import { IsCheckInResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useIsCheckIn() {
  const session = useGameStore((state) => state.session);

  return useQuery({
    queryKey: ['isCheckIn'],
    queryFn: async () => {
      const { payload } = await client.rpc(session, 'rpc_dailycheckin_ischecked', {});

      const { ischecked } = payload as IsCheckInResponse;

      return ischecked;
    },
    enabled: !!session,
  });
}
