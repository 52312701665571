import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CheckInResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useCheckIn() {
  const session = useGameStore((state) => state.session);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { payload } = await client.rpc(session, 'rpc_dailycheckin_checkin', {});

      return payload as CheckInResponse;
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['checkInInfo'] }),
  });
}
