import { useQuery } from '@tanstack/react-query';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useRanking() {
  const session = useGameStore((state) => state.session);

  return useQuery({
    queryKey: ['ranking'],
    queryFn: () =>
      client.listLeaderboardRecords(session, 'silver_board', [session.user_id], 200, null, null),
    enabled: !!session,
  });
}
