const MAX_WIDTH = 414;
const MAX_HEIGHT = 896;

export function getX() {
  const x = window.innerWidth / 2 - MAX_WIDTH / 2;
  return x > 0 ? x : 0;
}

export function getY() {
  const y = window.innerHeight / 2 - MAX_HEIGHT / 2;
  return y > 0 ? y : 0;
}

export function getWidth() {
  return window.innerWidth < MAX_WIDTH ? window.innerWidth : MAX_WIDTH;
}

export function getHeight() {
  return window.innerHeight < MAX_HEIGHT ? window.innerHeight : MAX_HEIGHT;
}

export function getScale() {
  const width = getWidth();

  return width / MAX_WIDTH;
}
