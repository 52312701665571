import { AnimatedSprite, Application, Assets, Spritesheet, Texture } from 'pixi.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '~/components/button';
import ScaleImage from '~/components/scale-image';

type Props = {
  rewards: [number, number];
  width: number;
  height: number;
  onComplete: () => void;
};

function AnimatedWave({ rewards, width, height, onComplete }: Props) {
  const [frame, setFrame] = useState(0);
  const ref = useRef<HTMLCanvasElement>(null);
  const appRef = useRef<Application>();
  const spriteRef = useRef<AnimatedSprite>();

  const loadAsset = useCallback(async (name: string) => {
    let spritesheet: Spritesheet = Assets.cache.get(name);

    if (!spritesheet) {
      spritesheet = await Assets.load(name);
    }

    return spritesheet;
  }, []);

  const animate = useCallback(
    (textures: Texture[]) => {
      if (!appRef.current) return;

      const sprite = new AnimatedSprite(textures);
      sprite.anchor = 0.5;
      sprite.x = width / 2;
      sprite.y = height / 2;
      sprite.width = width;
      sprite.height = height;
      sprite.animationSpeed = 0.2;
      sprite.loop = false;
      sprite.onComplete = onComplete;
      sprite.onFrameChange = (frame) => setFrame(frame);
      sprite.play();

      spriteRef.current = sprite;
      appRef.current.stage.addChild(spriteRef.current);
    },
    [height, width, onComplete]
  );

  useEffect(() => {
    if (frame === 3) {
      spriteRef.current.stop();
    }
  }, [frame]);

  useEffect(() => {
    if (!ref.current || appRef.current) return;

    appRef.current = new Application();
    appRef.current.init({ width, height, backgroundAlpha: 0, canvas: ref.current });
  }, [height, width]);

  useEffect(() => {
    if (!appRef.current || spriteRef.current) return;

    loadAsset('sprite/wave.json').then((spritesheet) => animate(spritesheet.animations.wave));
  }, [animate, loadAsset]);

  return (
    <div className="absolute inset-0 flex items-center justify-center p-2">
      <canvas ref={ref} />
      {frame === 3 ? (
        <div className="absolute inset-0 flex flex-col items-center py-8">
          <div className="flex flex-1 flex-col items-center justify-center gap-y-8">
            <div className="text-xl">{`YOU'VE RECEIVED`}</div>
            <div className="flex gap-x-10">
              {rewards[0] ? (
                <div className="flex flex-col items-center gap-y-1">
                  <ScaleImage src={`/img/fish/${rewards[0]}.png`} height={40} />
                  <div className="text-stroke-black">x1</div>
                </div>
              ) : null}
              {rewards[1] ? (
                <div className="flex flex-col items-center gap-y-1">
                  <ScaleImage src="/img/icon-silver.png" height={40} />
                  <div className="text-stroke-black">x{rewards[1]}</div>
                </div>
              ) : null}
            </div>
          </div>
          <Button
            onClick={() => spriteRef.current.play()}
            className="w-fit p-2 text-center text-md"
          >{`NEXT LEVEL >>`}</Button>
        </div>
      ) : null}
    </div>
  );
}

export default AnimatedWave;
