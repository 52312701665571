import { ReactNode } from 'react';
import { useScale } from '~/hooks/use-scale';
import { clsx } from '~/utils/style';

type Props = {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  children?: ReactNode;
  className?: string;
};

function BoxImage({ src, alt, width, height, children, className }: Props) {
  const { scale } = useScale();

  return (
    <div className="relative w-fit">
      <img
        src={src}
        alt={alt}
        style={{
          width: width ? width * scale : undefined,
          height: height ? height * scale : undefined,
        }}
      />
      <div className={clsx('absolute inset-0', className)}>{children}</div>
    </div>
  );
}

export default BoxImage;
