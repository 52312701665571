import { useCallback, useState } from 'react';
import BoxImage from '~/components/box-image';
import ButtonImage from '~/components/button-image';
import ScaleImage from '~/components/scale-image';
import { useClaimTask } from '~/hooks/use-claim-task';
import { useLocalStorage } from '~/hooks/use-local-storage';
import { useReportError } from '~/hooks/use-report-error';
import { useVerifyTask } from '~/hooks/use-verify-task';
import { TasksResponse } from '~/types/nakama';

type Props = {
  task: TasksResponse['tasks'][number];
};

function TaskItem({ task }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useLocalStorage(`task-${task.task.id}`, false);
  const { mutateAsync: verify } = useVerifyTask();
  const { mutateAsync: claim } = useClaimTask();
  const reportError = useReportError();

  const verifyTask = useCallback(async () => {
    setIsLoading(true);
    try {
      await verify(task.task.id);
    } catch (error) {
      reportError(error);
    } finally {
      setIsLoading(false);
    }
  }, [reportError, task, verify]);

  const claimTask = useCallback(async () => {
    setIsLoading(true);
    try {
      await claim(task.task.id);
    } catch (error) {
      reportError(error);
    } finally {
      setIsLoading(false);
    }
  }, [claim, reportError, task]);

  return (
    <div className="relative">
      <img src="/img/bar-task.png" alt="bar" className="min-h-24" />
      <div className="absolute inset-0 flex flex-col p-2">
        <div>{task.task.description}</div>
        <div className="flex flex-1 items-center justify-between">
          <div className="flex gap-x-2">
            {task.task.reward.map(({ type, amount }) => {
              if (type === 'turns') {
                return (
                  <BoxImage
                    key={type}
                    src="/img/slot-reward.png"
                    width={52}
                    className="flex items-center justify-center"
                  >
                    <ScaleImage src="/img/icon-turn.png" alt="turns" height={18} />
                    <div className="text-stroke-black absolute bottom-0 right-0.5 text-sm">
                      x{amount}
                    </div>
                  </BoxImage>
                );
              }

              if (type === 'silvers') {
                return (
                  <BoxImage
                    key={type}
                    src="/img/slot-reward.png"
                    width={52}
                    className="flex items-center justify-center"
                  >
                    <ScaleImage src="/img/icon-silver.png" alt="silver" height={30} />
                    <div className="text-stroke-black absolute bottom-0 right-0.5 text-sm">
                      {amount}
                    </div>
                  </BoxImage>
                );
              }

              return null;
            })}
          </div>
          {task.is_completed ? (
            <ButtonImage
              src={task.claimed ? '/img/btn-gray.png' : '/img/btn-green.png'}
              height={36}
              className="flex items-center justify-center"
              isLoading={isLoading}
              disabled={task.claimed}
              onClick={claimTask}
            >
              Claim
            </ButtonImage>
          ) : (
            <ButtonImage
              src="/img/btn-green.png"
              height={36}
              className="flex items-center justify-center"
              isLoading={isLoading}
              onClick={async () => {
                if (task.task.is_call_verify_immediately) {
                  window.open(task.task.url, '_bank');
                  await verifyTask();
                } else {
                  if (isVerifying) {
                    await verifyTask();
                  } else {
                    window.open(task.task.url, '_bank');
                    setIsVerifying(true);
                  }
                }
              }}
            >
              {isVerifying ? 'Verify' : task.task.button_name}
            </ButtonImage>
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskItem;
