import { Socket } from '@heroiclabs/nakama-js';
import { useCallback, useEffect, useState } from 'react';
import { FlipSquareResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { GameStatus, useGameStore } from './use-game';

export function useFlipEvent() {
  const [socket, setSocket] = useState<Socket>();
  const session = useGameStore((state) => state.session);
  const setSession = useGameStore((state) => state.setSession);
  const setStatus = useGameStore((state) => state.setStatus);
  const flipTile = useGameStore((state) => state.flipTile);

  const createSocket = useCallback(async () => {
    if (!session) return;

    if (socket) return socket;

    const newSocket = client.createSocket(true);
    const newSession = await newSocket.connect(session, true);
    setSession(newSession);
    setSocket(newSocket);

    return newSocket;
  }, [session, socket, setSession]);

  const flip = useCallback(
    async (tileIndex: number) => {
      const socket = await createSocket();

      if (!socket) return;

      setStatus(GameStatus.Flip);
      const { payload } = await socket.rpc(
        'rpc_flipmap_flip_squares',
        JSON.stringify({ squares: tileIndex })
      );
      const response = JSON.parse(payload) as FlipSquareResponse;

      return flipTile(response, tileIndex);
    },
    [createSocket, flipTile, setStatus]
  );

  useEffect(() => {
    createSocket();
  }, [createSocket]);

  useEffect(() => {
    if (!socket) return;

    socket.ondisconnect = () => setSocket(undefined);

    return () => {
      socket.disconnect(true);
    };
  }, [socket]);

  return flip;
}
