import ScaleImage from '~/components/scale-image';
import { useRanking } from '~/hooks/use-ranking';
import RankItem from './components/rank-item';

function RankPage() {
  const { data } = useRanking();

  return (
    <div className="flex h-full w-full flex-col items-center gap-y-2 bg-beach bg-cover pb-10">
      <ScaleImage src="/img/title-rank.png" width={200} height={89} />
      <div className="flex w-full flex-1 flex-col items-center gap-y-2 overflow-y-auto">
        {data?.records.map(({ rank, score, username }, index) => (
          <RankItem key={index} rankNo={rank} name={username} points={score} />
        ))}
      </div>
      {data && data.owner_records.length ? (
        <RankItem
          active
          rankNo={data.owner_records[0].rank}
          name={data.owner_records[0].username}
          points={data.owner_records[0].score}
        />
      ) : null}
    </div>
  );
}

export default RankPage;
