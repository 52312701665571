import { useEffect } from 'react';
import ScaleImage from '~/components/scale-image';
import { GameStatus, useGameStore } from '~/hooks/use-game';
import { usePlaySound } from '~/hooks/use-play-sound';
import { useScale } from '~/hooks/use-scale';
import AnimatedBomb from './animated-bomb';
import AnimatedWave from './animated-wave';
import Ceil from './ceil';

type Props = {
  flip: (tileIndex: number) => Promise<boolean>;
};

function Board({ flip }: Props) {
  const { scale, width } = useScale();
  const status = useGameStore((state) => state.status);
  const tiles = useGameStore((state) => state.tiles);
  const rewards = useGameStore((state) => state.rewards);
  const totalUnlocked = useGameStore((state) => state.totalUnlocked);
  const neededUnlocked = useGameStore((state) => state.neededUnlocked);
  const setStatus = useGameStore((state) => state.setStatus);
  const resetRewards = useGameStore((state) => state.resetRewards);
  const { playSoundBomb, playSoundClear } = usePlaySound();

  useEffect(() => {
    if (status === GameStatus.Bomb) {
      playSoundBomb();
    }

    if (status === GameStatus.Clear) {
      playSoundClear();
    }
  }, [playSoundBomb, playSoundClear, status]);

  return (
    <div style={{ minHeight: 425 * scale }} className="relative -bottom-3">
      <ScaleImage src="/img/playboard.png" alt="playboard" width={414} height={425} />
      <div
        style={{
          paddingTop: 20 * scale,
          paddingBottom: 16 * scale,
          paddingLeft: 16 * scale,
          paddingRight: 16 * scale,
        }}
        className="absolute inset-0 grid grid-cols-8 place-items-center gap-1"
      >
        {tiles.map(({ unlocked, value }, index) => (
          <Ceil key={index} tileIndex={index + 1} unlocked={unlocked} value={value} flip={flip} />
        ))}
      </div>
      {status === GameStatus.Bomb ? (
        <AnimatedBomb
          width={width - 16}
          height={width - 16}
          onComplete={() => setStatus(GameStatus.None)}
        />
      ) : status === GameStatus.Clear ? (
        <AnimatedWave
          rewards={rewards}
          width={width - 16}
          height={width - 16}
          onComplete={() => {
            setStatus(GameStatus.None);
            resetRewards();
          }}
        />
      ) : null}
      <div className="text-stroke-black absolute -top-2 right-2">
        {totalUnlocked}/{neededUnlocked}
      </div>
    </div>
  );
}

export default Board;
