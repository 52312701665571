import { useState } from 'react';

function getItem<T>(key: string, initialValue: T) {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    console.error(error);
    return initialValue;
  }
}

function setItem<T>(key: string, value: T) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState(() => getItem<T>(key, initialValue));

  const setValue = (value: T) => {
    setStoredValue(value);
    setItem(key, value);
  };

  return [storedValue, setValue];
}
