import { useQuery } from '@tanstack/react-query';
import { GetTileMapResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useTileMap() {
  const session = useGameStore((state) => state.session);
  const neededUnlocked = useGameStore((state) => state.neededUnlocked);
  const setTileMap = useGameStore((state) => state.setTileMap);
  const resetTileMap = useGameStore((state) => state.resetTileMap);
  const generateNextTileMap = useGameStore((state) => state.generateNextTileMap);

  return useQuery({
    queryKey: ['tileMap'],
    queryFn: async () => {
      const { payload } = await client.rpc(session, 'rpc_flipmap_get_tile_map', {});
      const { success, tileMap, flipTurns } = payload as GetTileMapResponse;

      if (success) {
        const { mapLevel, unlockedTiles, unlockSilverTiles, totalUnlocked, bombTiles } = tileMap;
        const { turns, turnCapacity, lastTurnUpdate } = flipTurns;
        let tiles = new Array(64).fill({ unlocked: false, value: null });
        tiles = tiles.map((tile, index) => {
          const tileIndex = index + 1;
          let unlocked = tile.unlocked;
          let value = tile.value;

          if (unlockedTiles.includes(tileIndex)) {
            unlocked = true;
          }

          const unlockedTile = unlockSilverTiles.find(({ index }) => tileIndex === index);

          if (unlockedTile) {
            value = unlockedTile.silver;
          }

          return { unlocked, value };
        });

        if (bombTiles > 0) {
          await resetTileMap();
          return;
        }

        if (totalUnlocked === neededUnlocked) {
          await generateNextTileMap();
          return;
        }

        setTileMap({ level: mapLevel, totalUnlocked, tiles, turns, turnCapacity, lastTurnUpdate });
      }

      return success;
    },
    enabled: !!session,
  });
}
