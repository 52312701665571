import { useEffect, useState } from 'react';

export function useCopy() {
  const [copied, setCopied] = useState(false);

  async function copy(text?: string) {
    if (!text) return;

    if (!navigator?.clipboard) return;

    await navigator.clipboard.writeText(text);
    setCopied(true);
  }

  useEffect(() => {
    if (!copied) return;

    const timeoutId = setTimeout(() => setCopied(false), 1000);

    return () => clearTimeout(timeoutId);
  }, [copied]);

  return { copied, copy };
}
