import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGameStore } from '~/hooks/use-game';
import { useReportError } from '~/hooks/use-report-error';
import { preloadImages } from '~/utils/image';

function BootPage() {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const authenticate = useGameStore((state) => state.authenticate);
  const reportError = useReportError();

  const initialize = useCallback(async () => {
    if (isChecked) return;

    setIsChecked(true);
    try {
      await authenticate();
      await preloadImages();
      navigate('/home');
    } catch (error) {
      reportError(error);
    }
  }, [authenticate, isChecked, navigate, reportError]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return <div className="h-full w-full bg-beach bg-cover" />;
}

export default BootPage;
