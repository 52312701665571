export async function parseError(error: unknown) {
  if (typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    return error.message;
  }

  if (error instanceof Response) {
    const { message } = await error.json();
    return message;
  }

  return String(error);
}
