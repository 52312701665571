import ScaleImage from '~/components/scale-image';
import { useTasks } from '~/hooks/use-tasks';
import TaskItem from './components/task-item';

function TaskPage() {
  const { data } = useTasks();

  return (
    <div className="relative flex h-full w-full flex-col items-center gap-y-2 bg-beach bg-cover pb-10">
      {data ? (
        <>
          <ScaleImage src="/img/title-task.png" width={200} height={89} />
          <div className="flex w-full flex-1 flex-col items-center gap-y-2 overflow-y-auto px-2">
            {data.tasks.map((task) => (
              <TaskItem key={task.task.id} task={task} />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default TaskPage;
