import { useQuery } from '@tanstack/react-query';
import { CheckInInfoResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useCheckInInfo() {
  const session = useGameStore((state) => state.session);

  return useQuery({
    queryKey: ['checkInInfo'],
    queryFn: async () => {
      const { payload } = await client.rpc(session, 'rpc_dailycheckin_get_user_info', {});

      const { checkin } = payload as CheckInInfoResponse;

      return checkin.checkInDay;
    },
    enabled: !!session,
  });
}
