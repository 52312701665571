import { useScale } from '~/hooks/use-scale';

type Props = {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
};

function ScaleImage({ src, alt, width, height, className }: Props) {
  const { scale } = useScale();

  return (
    <img
      src={src}
      alt={alt}
      style={{
        width: width ? width * scale : undefined,
        height: height ? height * scale : undefined,
      }}
      className={className}
    />
  );
}

export default ScaleImage;
