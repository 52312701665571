import { ReactNode } from 'react';
import { useScale } from '~/hooks/use-scale';
import { clsx } from '~/utils/style';
import Button from './button';
import Spinner from './spinner';

type Props = {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  children?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
};

function ButtonImage({
  src,
  alt,
  width,
  height,
  children,
  disabled,
  isLoading,
  className,
  onClick,
}: Props) {
  const { scale } = useScale();

  return (
    <Button disabled={disabled || isLoading} className="relative w-fit" onClick={onClick}>
      <img
        src={src}
        alt={alt}
        style={{
          width: width ? width * scale : undefined,
          height: height ? height * scale : undefined,
        }}
      />
      <div className={clsx('absolute inset-0', className)}>
        {isLoading ? <Spinner className="h-4 w-4" /> : children}
      </div>
    </Button>
  );
}

export default ButtonImage;
