import { useCallback, useEffect, useState } from 'react';
import BoxImage from '~/components/box-image';
import ButtonImage from '~/components/button-image';
import Modal from '~/components/modal';
import ScaleImage from '~/components/scale-image';
import { useGameStore } from '~/hooks/use-game';
import { useIsClaimedMaintenance } from '~/hooks/use-is-claimed-maintenance';
import { ClaimedMaintenanceResponse } from '~/types/nakama';
import { client } from '~/utils/nakama-client';

function ModalClaimMaintenance() {
  const [isOpen, setIsOpen] = useState(false);
  const [turns, setTurns] = useState(0);
  const [silver, setSilver] = useState(0);
  const session = useGameStore((state) => state.session);
  const addRewards = useGameStore((state) => state.addRewards);
  const { data: isClaimed } = useIsClaimedMaintenance();

  const claim = useCallback(async () => {
    const { payload } = await client.rpc(session, 'rpc_maintenance_rewards_claim', {});
    const { rewards } = payload as ClaimedMaintenanceResponse;
    const { turns, silver } = rewards;
    setTurns(turns);
    setSilver(silver);
    addRewards(turns, silver);
    setIsOpen(true);
  }, [addRewards, session]);

  useEffect(() => {
    if (isClaimed !== undefined && !isClaimed) {
      claim();
    }
  }, [claim, isClaimed]);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <BoxImage
        src="/img/board-popup.png"
        alt="popup"
        width={382}
        height={407}
        className="flex flex-col items-center py-10"
      >
        <div className="text-center text-xl">BONUS REWARD</div>
        <div className="flex flex-1 items-center justify-center">
          <div className="flex items-end gap-x-10">
            {silver ? (
              <div className="flex flex-col items-center gap-y-2">
                <div className="flex h-14 items-center">
                  <ScaleImage src="/img/icon-silver.png" height={56} />
                </div>
                <div className="text-stroke-black text-lg">x{silver}</div>
              </div>
            ) : null}
            {turns ? (
              <div className="flex flex-col items-center gap-y-2">
                <div className="flex h-14 items-center">
                  <ScaleImage src="/img/icon-turn.png" height={32} />
                </div>
                <div className="text-stroke-black text-xl">x{turns}</div>
              </div>
            ) : null}
          </div>
        </div>
        <ButtonImage
          src="/img/btn-green.png"
          height={50}
          className="flex items-center justify-center text-md"
          onClick={() => setIsOpen(false)}
        >
          Continue
        </ButtonImage>
      </BoxImage>
    </Modal>
  );
}

export default ModalClaimMaintenance;
