import BoxImage from '~/components/box-image';
import ButtonImage from '~/components/button-image';
import Modal from '~/components/modal';

type Props = {
  message: string;
  isOpen: boolean;
  onClose: () => void;
};

function ModalError({ message, isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <BoxImage
        src="/img/board-popup.png"
        alt="popup"
        width={382}
        height={407}
        className="flex flex-col items-center py-10"
      >
        <div className="flex flex-1 items-center justify-center text-center text-xl">{message}</div>
        <ButtonImage
          src="/img/btn-green.png"
          height={50}
          className="flex items-center justify-center text-lg"
          onClick={onClose}
        >
          Close
        </ButtonImage>
      </BoxImage>
    </Modal>
  );
}

export default ModalError;
