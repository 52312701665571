export const metadata = {
  '1': {
    turns: 100,
    silver: 0,
  },
  '2': {
    turns: 0,
    silver: 1000,
  },
  '3': {
    turns: 0,
    silver: 1500,
  },
  '4': {
    turns: 125,
    silver: 0,
  },
  '5': {
    turns: 0,
    silver: 3000,
  },
  '6': {
    turns: 0,
    silver: 4000,
  },
  '7': {
    turns: 200,
    silver: 5000,
  },
  '8': {
    turns: 150,
    silver: 0,
  },
  '9': {
    turns: 0,
    silver: 2500,
  },
  '10': {
    turns: 0,
    silver: 4000,
  },
  '11': {
    turns: 175,
    silver: 0,
  },
  '12': {
    turns: 0,
    silver: 4500,
  },
  '13': {
    turns: 0,
    silver: 5500,
  },
  '14': {
    turns: 275,
    silver: 6500,
  },
  '15': {
    turns: 200,
    silver: 0,
  },
  '16': {
    turns: 0,
    silver: 4000,
  },
  '17': {
    turns: 0,
    silver: 4500,
  },
  '18': {
    turns: 225,
    silver: 0,
  },
  '19': {
    turns: 0,
    silver: 6000,
  },
  '20': {
    turns: 0,
    silver: 7000,
  },
  '21': {
    turns: 300,
    silver: 8000,
  },
  '22': {
    turns: 250,
    silver: 0,
  },
  '23': {
    turns: 0,
    silver: 5500,
  },
  '24': {
    turns: 0,
    silver: 6000,
  },
  '25': {
    turns: 275,
    silver: 0,
  },
  '26': {
    turns: 0,
    silver: 7500,
  },
  '27': {
    turns: 0,
    silver: 8500,
  },
  '28': {
    turns: 375,
    silver: 9500,
  },
};
