import { ReactNode, useEffect } from 'react';
import { useSound } from 'use-sound';
import { SoundContext } from '~/hooks/use-play-sound';

type Props = {
  children: ReactNode;
};

function SoundProvider({ children }: Props) {
  const [playSoundBg, { stop: stopSoundBg }] = useSound('/audio/bg-song.mp3', {
    volume: 0.2,
    loop: true,
  });
  const [playSoundClick] = useSound('/audio/click.mp3', { volume: 0.5 });
  const [playSoundBomb] = useSound('/audio/bomb.mp3');
  const [playSoundClear] = useSound('/audio/cleared.mp3');
  const [playSoundFlip] = useSound('/audio/flip.mp3', { volume: 0.5 });
  const [playSoundSilver] = useSound('/audio/silver.mp3');

  useEffect(() => {
    playSoundBg();

    return () => {
      stopSoundBg();
    };
  }, [playSoundBg, stopSoundBg]);

  return (
    <SoundContext.Provider
      value={{
        playSoundClick,
        playSoundBomb,
        playSoundClear,
        playSoundFlip,
        playSoundSilver,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
}

export default SoundProvider;
