import { ReactNode, useEffect, useState } from 'react';
import { ScaleContext } from '~/hooks/use-scale';
import { getHeight, getScale, getWidth, getX, getY } from '~/utils/scale';

type Props = {
  children: ReactNode;
};

function ScaleProvider({ children }: Props) {
  const [x, setX] = useState(getX());
  const [y, setY] = useState(getY());
  const [width, setWidth] = useState(getWidth());
  const [height, setHeight] = useState(getHeight());
  const [scale, setScale] = useState(1);

  useEffect(() => {
    function handleScale() {
      setX(getX());
      setY(getY());
      setWidth(getWidth());
      setHeight(getHeight());
      setScale(getScale());
    }

    handleScale();
    window.addEventListener('resize', handleScale);

    return () => window.removeEventListener('resize', handleScale);
  }, []);

  return (
    <ScaleContext.Provider value={{ x, y, width, height, scale }}>{children}</ScaleContext.Provider>
  );
}

export default ScaleProvider;
