import { ButtonHTMLAttributes } from 'react';
import { usePlaySound } from '~/hooks/use-play-sound';

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

function Button({ children, onClick, ...props }: Props) {
  const { playSoundClick } = usePlaySound();

  return (
    <button
      {...props}
      onClick={(e) => {
        playSoundClick();
        onClick(e);
      }}
    >
      {children}
    </button>
  );
}

export default Button;
