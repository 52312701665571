import BoxImage from '~/components/box-image';
import Modal from '~/components/modal';
import ScaleImage from '~/components/scale-image';
import { metadata } from '~/config/daily-checkin';
import { useCheckInInfo } from '~/hooks/use-check-in-info';
import { useScale } from '~/hooks/use-scale';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const days = new Array(28).fill(null);

function ModalDailyReward({ isOpen, onClose }: Props) {
  const { scale } = useScale();
  const { data: checkInDay } = useCheckInInfo();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <BoxImage
        src="/img/board-popup-large.png"
        alt="popup"
        width={382}
        height={635}
        className="flex flex-col gap-y-2 px-1 py-4"
      >
        <ScaleImage src="/img/text-daily-reward.png" width={300} height={37} className="mx-auto" />
        <div className="grid flex-1 grid-cols-4 place-items-center gap-x-px gap-y-1 overflow-y-auto px-2">
          {days.map((_, index) => {
            const day = index + 1;
            const { turns, silver } = metadata[day];
            const hasBoth = turns > 0 && silver > 0;
            const isChecked = day < checkInDay;

            return (
              <div key={index} className="relative px-1 pt-1">
                <BoxImage src="/img/board-day.png" width={81} height={105}>
                  <div className="absolute inset-0 flex flex-col">
                    {hasBoth ? (
                      <div className="flex flex-1 flex-col px-2 pt-2">
                        <div className="flex flex-1 flex-col items-start">
                          <div className="flex flex-col items-center justify-center gap-y-px">
                            <ScaleImage src="/img/icon-turn.png" width={38} height={16} />
                            <div className="text-xs sm:text-sm">x{turns}</div>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col items-end">
                          <div className="flex flex-col items-center justify-center gap-y-px">
                            <ScaleImage src="/img/icon-silver.png" width={26} height={24} />
                            <div className="text-xs sm:text-sm">x{silver}</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex-1">
                        <div className="flex h-full flex-col">
                          <div className="flex flex-1 items-end justify-center">
                            {turns > 0 ? (
                              <ScaleImage
                                src="/img/icon-turn.png"
                                width={57}
                                height={24}
                                className="mb-2"
                              />
                            ) : (
                              <ScaleImage src="/img/icon-silver.png" width={39} height={36} />
                            )}
                          </div>
                          <div className="py-1 text-center text-xs sm:text-sm">
                            x{turns || silver}
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      style={{ height: 28 * scale }}
                      className="flex items-center justify-center text-sm sm:text-base"
                    >
                      DAY{day}
                    </div>
                  </div>
                  {isChecked ? (
                    <ScaleImage
                      src="/img/mask-day.png"
                      width={81}
                      height={105}
                      className="absolute"
                    />
                  ) : null}
                </BoxImage>
                {isChecked ? (
                  <ScaleImage
                    src="/img/icon-tick.png"
                    height={20}
                    className="absolute right-0 top-px"
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      </BoxImage>
    </Modal>
  );
}

export default ModalDailyReward;
