import { useQuery } from '@tanstack/react-query';
import { client } from '~/utils/nakama-client';
import { useGameStore } from './use-game';

export function useSilver() {
  const session = useGameStore((state) => state.session);
  const setSilver = useGameStore((state) => state.setSilver);

  return useQuery({
    queryKey: ['silver'],
    queryFn: async () => {
      const { wallet } = await client.getAccount(session);
      const { silver } = JSON.parse(wallet) as { silver: number };

      if (silver) {
        setSilver(silver);
      }

      return silver ?? 0;
    },
    enabled: !!session,
  });
}
