import { useEffect, useState } from 'react';
import ScaleImage from '~/components/scale-image';
import { GameStatus, useGameStore } from '~/hooks/use-game';
import { usePlaySound } from '~/hooks/use-play-sound';
import { useReportError } from '~/hooks/use-report-error';

type Props = {
  tileIndex: number;
  unlocked: boolean;
  value: number | null;
  flip: (tileIndex: number) => Promise<boolean>;
};

function Ceil({ tileIndex, unlocked, value, flip }: Props) {
  const [isUnlocked, setIsUnlocked] = useState(unlocked);
  const turns = useGameStore((state) => state.turns);
  const status = useGameStore((state) => state.status);
  const setStatus = useGameStore((state) => state.setStatus);
  const reportError = useReportError();
  const { playSoundFlip, playSoundSilver } = usePlaySound();

  useEffect(() => {
    setIsUnlocked(unlocked);
  }, [unlocked]);

  return (
    <button
      disabled={isUnlocked || status === GameStatus.Flip}
      className="h-full w-full"
      onClick={async () => {
        if (turns === 0) {
          reportError(new Error('out of turns'));
          return;
        }

        setIsUnlocked(true);
        setStatus(GameStatus.Flip);
        try {
          playSoundFlip();
          const hasSilver = await flip(tileIndex);
          if (hasSilver) {
            playSoundSilver();
          }
        } catch (error) {
          setStatus(GameStatus.None);
          setIsUnlocked(false);
          reportError(error);
        }
      }}
    >
      <div className="relative h-full w-full">
        <img
          src="/img/ceil.png"
          alt="ceil"
          style={{
            backfaceVisibility: 'hidden',
            transition: 'transform 0.5s',
            transformStyle: 'preserve-3d',
            transform: `rotateY(${isUnlocked ? 180 : 0}deg)`,
          }}
          className="absolute"
        />
        <div
          style={{
            backfaceVisibility: 'hidden',
            transition: 'transform 0.5s',
            transformStyle: 'preserve-3d',
            transform: `rotateY(${isUnlocked ? 0 : 180}deg)`,
          }}
          className="absolute"
        >
          <img src="/img/ceil-revealed.png" alt="ceil" className="h-full w-full" />
          {value ? (
            <div className="absolute inset-0 flex flex-col items-center justify-center gap-y-px">
              <ScaleImage src="/img/icon-silver.png" alt="silver" height={24} />
              <div className="text-sm">{value}</div>
            </div>
          ) : null}
        </div>
      </div>
    </button>
  );
}

export default Ceil;
