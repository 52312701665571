import { animated, useTransition } from '@react-spring/web';
import { ReactNode } from 'react';
import { useScale } from '~/hooks/use-scale';

type Props = {
  isOpen: boolean;
  width?: number;
  height?: number;
  children: ReactNode;
  onClose?: () => void;
};

function Modal({ isOpen, width, height, children, onClose }: Props) {
  const { x, y, width: displayWidth, height: displayHeight } = useScale();

  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions((style, isOpen) =>
    isOpen ? (
      <animated.div
        role="presentation"
        style={{
          opacity: style.opacity,
          left: x,
          top: y,
          width: displayWidth,
          height: displayHeight,
        }}
        onClick={onClose}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 px-4"
      >
        <animated.div
          role="presentation"
          style={{ ...style, width, height }}
          className="relative"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </animated.div>
      </animated.div>
    ) : null
  );
}

export default Modal;
